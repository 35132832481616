import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titlePadding: {
    padding: "16px",
  },
  bold: {
    fontWeight: "bold",
  },
  appBarTitle: {
    flexGrow: 1,
  },
  toolBar: {
    justifyContent: "space-between",
  },
  appBar: {
    backgroundColor: "#f7f7f7",
    color: "#3f51b5",
    marginBottom: "20px",
  },
  logo: {
    maxWidth: 160,
  },
  card: {
    margin: "15px 0 20px 0",
  },
  unit: {
    display: "flex",
    flexDirection: "column",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
  },
  noPadding: {
    padding: "0px",
  },
  unitActions: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "flex-end",
  },
  gridTable: {
    marginTop: "20px",
  },
  modalWindow: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  modalWrapper: {
    maxHeight: "92vh",
    overflow: "hidden",
    backgroundColor: "#fff",
    minWidth: "600px",
    borderRadius: "16px",
    padding: "24px 8px 24px 24px",
    "& > div": {
      overflowY: "auto",
      height: "100%",
    },
    "& > div > .MuiContainer-root": {
      padding: "0 12px 0 0",
    },
  },
});

export default useStyles;
