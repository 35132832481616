import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Typography,
  FormControl,
  IconButton,
  Button,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Units from './units';
import useStyles from '../App/styles';
import useUpdateUnitProjectRelation from '../../hooks/useUpdateUnitProjectRelation';
import { unitsToArray } from '../../helpers/units';

const CreatePowerUnitsForProject = () => {
  const classes = useStyles();
  const history = useHistory();
  const { projectId } = useParams();
  const { register, errors, control, getValues, handleSubmit } = useForm();
  const { error: createError, createRelation } = useUpdateUnitProjectRelation();

  const sendRequest = (fields) => {
    createRelation(projectId, unitsToArray(fields));
   };

  const goBack = useCallback(
    () => history.push('/projects'),
    [history],
  );

  return (
    <Container
      maxWidth="lg"
    >
      <Box className={classes.title} >
        <Typography variant="h5">
          Add power units
        </Typography>
        <IconButton
          color="primary"
          aria-label="back"
          onClick={goBack}
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <FormControl
        component="form"
        margin="normal"
        fullWidth
        autoComplete="off"
        onSubmit={handleSubmit(sendRequest)}
      >
        <Units
          errors={errors}
          register={register}
          control={control}
          formValues={getValues}
        />
        <Button
          variant="outlined"
          color="primary"
          type="submit"
        >
          Save
        </Button>
      </FormControl>
    </Container>
  )
};

export default CreatePowerUnitsForProject;
