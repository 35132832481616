import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetch } from '../helpers/api';
import { PROJECTS } from '../helpers/urls';

const useCreateProject = () => {
  const history = useHistory();
  const [error, setError] = useState(null);

  const createProject = (data) => {
    fetch(PROJECTS, 'POST', data)
      .then((res) => {
        history.push('projects');
      })
      .catch((error) => {
        setError(error);
      });
  };

  return { error, createProject };
};

export default useCreateProject;

