import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetch } from '../helpers/api';
import { PROJECT_POWER_UNITS } from '../helpers/urls';


const useUnits = () => {
  const [units, setUnits] = useState([]);
  const { projectId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(
    () => {
      fetch(PROJECT_POWER_UNITS(projectId))
        .then((res) => res.json())
        .then((res) => {
          setUnits(
            res.map(({ power_unit, count_of_units, s_n, p_n, u_n , id}, index) => ({
              id: index,
              [`id${index}`]: id,
              [`power_unit${index}`]: power_unit,
              [`count_of_units${index}`]: count_of_units,
              [`s_n${index}`]: s_n,
              [`p_n${index}`]: p_n,
              [`u_n${index}`]: u_n,
            }))
          );
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    },
    [],
  );

  const addUnit = () => setUnits((existing) =>
    [...existing, { id: existing.length ? Math.max(...existing.map(({id}) => id)) + 1 : 0 }]
  );

  const removeUnit = (index) => () =>
    setUnits((existing) =>
      existing.filter(({ id }) => id !== index)
    );

  return { units, addUnit, removeUnit };
};

export default useUnits;
