import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetch, setToken, setCurrentUser } from '../helpers/api';
import { LOGIN, CURRENT_USER } from '../helpers/urls';

const useLogin = () => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const login = (data) => {
    fetch(LOGIN, 'POST', data, {}, false)
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        setToken(res.access);
        setUser(res);
        history.push('projects');
      })
      .catch((error) => {
        setError(error);
      })
      .then(() => {
        fetch(CURRENT_USER, 'GET')
            .then((res) => res.json())
            .then((res) => {
              setCurrentUser(res);
            })
            .catch((error) => {
                setError(error)
            })
      });
  };

  return { user, error, login };
};

export default useLogin;
