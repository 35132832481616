import React, {useState} from 'react';
import MaterialTable from 'material-table';
import { Container } from '@material-ui/core';
import useDatebank from '../../hooks/useDatebank';
import columns from './columns';
import Modal from "@material-ui/core/Modal";
import useStyles from "../App/styles";
import ImportPowerUnit from "./importPowerUnit";


const Databank = () => {
  const options = {
    exportButton: true,
    filtering: true,
  };

  const classes = useStyles();
  const { loading, datebank, updatePowerUnit, disableArchiving, harmonicColumns, error } = useDatebank();
  const fullColumnsList = harmonicColumns ? [...columns, ...harmonicColumns] : columns;
  const [open, setOpen] = useState(false);

  const toggleArchiveItem = (power_unit_id, is_archived) => {
    updatePowerUnit(power_unit_id, {'is_archived': is_archived});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const actions = [];

  actions.push({
      icon: "add_box",
      tooltip: "Import xlsx",
      position: "toolbar",
      onClick: handleOpen,
  });

  actions.push(
    function(rowData) {
        let icon = 'archive';
        let tooltip = 'Archiv';
        if (rowData.is_archived) {
            icon = 'unarchive';
            tooltip = 'Dearchivieren';
        }
        return {
            icon: icon,
            tooltip: tooltip,
            disabled: disableArchiving,
            onClick: (event, rowData) => toggleArchiveItem(rowData.id, !rowData.is_archived),
        };
    }
  );

  return (
    <Container>
      <MaterialTable
        title="Datebank"
        columns={fullColumnsList}
        data={datebank}
        isLoading={loading}
        options={options}
        actions={actions}
      />
        <Container>
            <Modal
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Container maxWidth="xs" className={classes.modalWindow}>
                    <ImportPowerUnit sendRequest={(data) => {console.log(data);}} handleClose={handleClose}/>
                </Container>
            </Modal>
        </Container>
    </Container>
  );
}

export default Databank;
