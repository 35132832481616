import { useState, useEffect } from 'react';
import { fetch } from '../helpers/api';
import { PROJECTS } from '../helpers/urls';

const useProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(
    () => {
      fetch(PROJECTS)
        .then((res) => res.json())
        .then((res) => {
          setProjects(res);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
       });
    },
    [],
  );

  return { projects, loading, error };
};

export default useProjects;
