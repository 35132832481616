import {useState, useEffect} from 'react';
import {fetch} from '../helpers/api';
import {ABOUT_INFO} from '../helpers/urls';

const useAbout = () => {
    const [aboutInfo, setAboutInfo] = useState({
        api_build_date: 0,
        api_version: "-"
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(
        () => {
            fetch(ABOUT_INFO)
                .then((res) => res.json())
                .then((res) => {
                    setAboutInfo(res);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        },
        [],
    );

    return {aboutInfo, loading, error};
};

export default useAbout;
