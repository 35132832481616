import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Alert } from "@material-ui/lab";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import useGetProject from "../../hooks/useGetProject";
import useGridOperation from "../../hooks/useGridOperation";
import useUpdateProjectInfo from "../../hooks/useUpdateProjectInfo";
import useStyles from "../App/styles";
import ProjectSchema from "../CreateProject/validation";
import GridOperator from "./gridOperator";
import GridOperatorForm from "./gridOperatorForm";

const ViewProject = () => {
  const classes = useStyles();
  const history = useHistory();
  const { register, errors, reset, handleSubmit } = useForm({
    validationSchema: ProjectSchema,
  });
  const { getProject, error, loading, project } = useGetProject(reset);
  const {
    error: createError,
    isSend,
    setSendStatus,
    requestGridOperation,
  } = useGridOperation();
  const { updateProjectInfo, requestError, isLoading } = useUpdateProjectInfo();
  const [open, setOpen] = useState(false);
  const [isInfoShown, setInfoState] = useState(false);

  const calculationOptions = [
    { label: "VDE-AR-N 4110", value: "VDE-AR-N_4110" },
    { label: "VDE-AR-N 4120", value: "VDE-AR-N_4120" },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let gridOperatorExists =
    project.grid_operations && project.grid_operations.length > 0;

  const onSubmit = (data) => updateProjectInfo(data);
  const onError = (errors) => console.log(errors);

  useEffect(() => {
    if (isSend) {
      getProject();
      setSendStatus(false);
      setInfoState(true);
    }
  }, [isSend]);

  const goBack = useCallback(() => history.push("/projects"), [history]);

  const sendGridOperation = (...params) => {
    requestGridOperation(!gridOperatorExists, ...params);
    handleClose();
  };

  useEffect(() => {
    if (isInfoShown) {
      setTimeout(() => {
        setInfoState(false);
      }, 3000);
    }
  }, [isInfoShown]);

  return (
    <Container maxWidth="sm">
      <Box className={classes.title}>
        <Typography variant="h5">Project Information</Typography>
        <IconButton color="primary" aria-label="back" onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <FormControl
        component="form"
        margin="normal"
        fullWidth
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <TextField
          id="name"
          name="name"
          label="Name *"
          margin="normal"
          error={Boolean(errors.name)}
          helperText={errors.name && errors.name.message}
          inputRef={register}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="external_number"
          name="external_number"
          label="Ext. number *"
          margin="normal"
          error={Boolean(errors.external_number)}
          helperText={errors.external_number && errors.external_number.message}
          inputRef={register}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="manager"
          name="manager"
          label="Manager *"
          margin="normal"
          error={Boolean(errors.manager)}
          helperText={errors.manager && errors.manager.message}
          inputRef={register}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button variant="outlined" color="primary" type="submit">
          Update
        </Button>
      </FormControl>

      {isInfoShown && (
        <Alert severity="success">Grid Operator is successfully saved!</Alert>
      )}

      <Container className={classes.noPadding}>
        <Box className={classes.cardActions}>
          <Button color="primary" variant="outlined" onClick={handleOpen}>
            {gridOperatorExists
              ? "Edit Grid Operation"
              : "Create Grid Operation"}
          </Button>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Container maxWidth="sm" className={classes.modalWindow}>
            <div className={classes.modalWrapper}>
              <div>
                <h2 id="simple-modal-title" className={classes.modalTitle}>
                  {gridOperatorExists
                    ? "Edit Grid Operation"
                    : "Create Grid Operation"}
                </h2>
                <GridOperatorForm
                  sendRequest={sendGridOperation}
                  data={gridOperatorExists && project.grid_operations[0]}
                  standard={
                    gridOperatorExists && project.grid_operations[0].standard
                  }
                />
              </div>
            </div>
          </Container>
        </Modal>
      </Container>
      {gridOperatorExists && <GridOperator data={project.grid_operations[0]} />}
    </Container>
  );
};

export default ViewProject;
