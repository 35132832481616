import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Container, FormControl, TextField, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useLogin from "../../hooks/useLogin";
import LoginSchema from "./validation";
import useStyles from "./styles";

const Login = () => {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm({
    validationSchema: LoginSchema,
  });
  const { error: loginError, login } = useLogin();

  const validateUser = useCallback((data) => login(data), [login]);

  return (
    <Container maxWidth="sm" classes={classes}>
      <FormControl
        component="form"
        margin="normal"
        fullWidth
        onSubmit={handleSubmit(validateUser)}
      >
        {loginError && (
          <Alert variant="outlined" severity="error">
            User not found
          </Alert>
        )}
        <TextField
          id="email"
          name="email"
          label="Email *"
          margin="normal"
          error={Boolean(errors.name)}
          helperText={errors.name && errors.name.message}
          inputRef={register}
        />
        <TextField
          id="password"
          name="password"
          label="Password *"
          margin="normal"
          type="password"
          error={Boolean(errors.password)}
          helperText={errors.password && errors.password.message}
          inputRef={register}
        />
        <Button variant="outlined" color="primary" type="submit">
          Login
        </Button>
      </FormControl>
    </Container>
  );
};

export default Login;
