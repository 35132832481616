import React, { Fragment } from 'react';
import { createBrowserHistory } from 'history';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { isAuthorized } from '../../helpers/api';

import Login from '../Login';
import Projects from '../Projects';
import CreateProject from '../CreateProject';
import Databank from '../Databank';
import ViewProject from '../ViewProjectPage';
import CreatePowerUnitsForProject from '../CreatePowerUnits';
import AppBar from './appBar';
import About from '../About'


const customHistory = createBrowserHistory();


function PublicRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        <Fragment>
          <AppBar showLinks={false} />
          {children}
        </Fragment>
      }
    />
  );
}


function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthorized() ? (
          <Fragment>
            <AppBar showLinks={true} />
            {children}
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}


function App() {
  return (
    <Router history={customHistory} >
      <Switch>
        <PublicRoute exact path="/">
          <Redirect exact from="/" to="/login" />
        </PublicRoute>

        <PublicRoute exact path="/login">
          <Login />
        </PublicRoute>

        <PrivateRoute exact path="/projects">
          <Projects />
        </PrivateRoute>

        <PrivateRoute path="/create-project">
          <CreateProject />
        </PrivateRoute>

        <PrivateRoute path="/project/:projectId/add-power-units/">
          <CreatePowerUnitsForProject />
        </PrivateRoute>

        <PrivateRoute path="/project/:projectId/info/">
          <ViewProject />
        </PrivateRoute>

        <PrivateRoute path="/datebank">
          <Databank />
        </PrivateRoute>
        <PrivateRoute exact path="/about">
          <About />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;
