import { Button, Container } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from "material-table";
import React from "react";
import useProjects from "../../hooks/useProjects";
import useProjectsActions from "../../hooks/useProjectsActions";
import useStyles from "./styles";

const columns = [
  {
    title: "Number",
    field: "external_number",
  },
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Manager",
    field: "manager",
  },
];

const options = {
  exportButton: true,
  actionsColumnIndex: -1,
};

const Projects = () => {
  const { error, loading, projects } = useProjects();
  const actions = useProjectsActions();
  const styles = useStyles();

  const components = {
    Action: (props) => {
      if (props.action.icon === "create-project") {
        return (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={props.action.onClick}
          >
            Create Project
          </Button>
        );
      } else if (props.action.icon === "manage-power-units") {
        return (
          <Button
            variant="outlined"
            color="primary"
            className={styles.listButton}
            onClick={props.action.onClick(props.data.id)}
          >
            Manage Units
          </Button>
        );
      } else if (props.action.icon === "project-detail") {
        return (
          <Button
            variant="outlined"
            color="primary"
            className={styles.listButton}
            onClick={props.action.onClick(props.data.id)}
          >
            View
          </Button>
        );
      }
    },
  };

  return (
    <Container>
      <MaterialTable
        title="All Projects"
        columns={columns}
        data={projects}
        isLoading={loading}
        options={options}
        components={components}
        actions={actions}
      />
    </Container>
  );
};

export default Projects;
