import React from 'react';


export default [
  {
    title: 'Erstellt von',
    field: 'created_by',
  },
  {
    title: 'Hergestellt in',
    field: 'created_at',
    type: 'date',
  },
  {
    title: 'Ist aktiv',
    field: 'is_archived',
    defaultFilter: [
        'false',
    ],
    lookup: {
      'true': 'Ist archiviert',
      'false': 'Aktiv',
    },
  },
  {
    title: 'Hersteller der EZE voll',
    field: 'eze_manufacturer_full',
  },
  {
    title: 'Bemerkung',
    field: 'comment',
  },
  {
    title: 'Einheitenzertifikat Nr.',
    field: 'certificate',
  },
  {
    title: 'Gültigkeitsdatum',
    field: 'validity_date',
  },
  {
    title: 'Richtlinie',
    field: 'directive',
  },
  {
    title: <span>k<sub>f1</sub></span>, field: 'k_f1',
  },
  {
    title: <span>k<sub>f2</sub></span>, field: 'k_f2',
  },
  {
    title: <span>k<sub>f3</sub></span>, field: 'k_f3',
  },
  {
    title: <span>k<sub>f4</sub></span>, field: 'k_f4',
  },
  {
    title: <span>k<sub>i, max</sub></span>, field: 'k_i_max',
  },
  {
    title: <span>k<sub>u1</sub></span>, field: 'k_u1',
  },
  {
    title: <span>k<sub>u2</sub></span>, field: 'k_u2',
  },
  {
    title: <span>k<sub>u3</sub></span>, field: 'k_u3',
  },
  {
    title: <span>k<sub>u4</sub></span>, field: 'k_u4',
  },
  {
    title: <span>n<sub>10m</sub></span>, field: 'n_10m',
  },
  {
    title: <span>n<sub>120m</sub></span>, field: 'n_120m',
  },
  {
    title: <span>c<sub>1</sub></span>, field: 'c1',
  },
  {
    title: <span>c<sub>2</sub></span>, field: 'c2',
  },
  {
    title: <span>c<sub>3</sub></span>, field: 'c3',
  },
  {
    title: <span>c<sub>4</sub></span>, field: 'c4',
  },
];