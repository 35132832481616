import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetch } from "../helpers/api";
import { PROJECT_VIEW } from "../helpers/urls";

const useGetProject = (reset) => {
  const { projectId } = useParams();
  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getProject = () => {
    fetch(PROJECT_VIEW(projectId))
      .then((res) => res.json())
      .then((res) => {
        reset(res);
        setProject(res);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(getProject, []);

  return { project, loading, error, getProject };
};

export default useGetProject;
