import React from "react";
export const generateLabelElement = (key, transformerPowerCounter) => {
  switch (key) {
    case "u_n":
      return (
        <span>
          U<sub>n</sub> in kV
        </span>
      );
    case "u_c":
      return (
        <span>
          U<sub>c</sub> in kV
        </span>
      );
    case "s_kV":
      return (
        <span>
          S<sub>kv</sub> in MVA
        </span>
      );
    case "line_impedance":
      return <span>Netzimpedanzwinkel</span>;
    case "total":
      return <span>SGesamt in MVA</span>;
    case "network":
      return <span>SNetz in MVA</span>;
    case "s_0":
      return (
        <span>
          S <sub>0</sub> in MVA
        </span>
      );
    case "ripple_control_frequency":
      return <span>Rundsteuerfrequenz in Hz</span>;
    case "k_B":
      return <span>kB</span>;
    case "k_E":
      return <span>kE</span>;
    case "k_S":
      return <span>kS</span>;
    case "g":
      return <span>g (1 für Wind; 0.9 für PV)</span>;
    case "transformers_powers":
      return <span>Trafoleistung {transformerPowerCounter} (MVA)</span>;
    case "resonance_factor_ku":
      return <span>Resonanzfaktoren ku</span>;
    case "resonance_factor_kb":
      return <span>Resonanzfaktoren kb</span>;
    case "resonance_factor_kv":
      return <span>Resonanzfaktoren kv</span>;
    case "rt":
      return <span>R t</span>;
    case "xt":
      return <span>X t</span>;
    default:
      return null;
  }
};

export const convertGridOperationData = (data) => {
  const entries = Object.entries(data);

  const newArr = entries.map((item) => {
    if (Array.isArray(item[1])) {
      const values = item[1];
      return values.map((value) => [item[0], value]);
    } else return item;
  });
  const modified = newArr.reduce((accum, current) => {
    if (Array.isArray(current[0])) {
      current.forEach((item) => accum.push(item));
    } else {
      accum.push(current);
    }
    return accum;
  }, []);
  return modified.filter((item) => {
    if (item[0] === "standard") return false;
    if (item[0] === "id") return false;
    if (item[0] === "s_0" && item[1] === null) return true;
    if ((item[1] === null) | (item[1] === undefined)) return false;
    if (item.length) return true;
  });
};
