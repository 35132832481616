import { useState } from "react";
import { useParams } from "react-router-dom";
import { fetch } from "../helpers/api";
import { PROJECT_VIEW } from "../helpers/urls";

const useUpdateProjectInfo = () => {
  const { projectId } = useParams();
  const [projectInfo, setProjectInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [requestError, setRequestError] = useState(null);

  const updateProjectInfo = (formData) => {
    fetch(`${PROJECT_VIEW(projectId)}/`, "PATCH", formData)
      .then((res) => res.json())
      .then((res) => {
        setProjectInfo(res);
        setIsLoading(false);
      })
      .catch((error) => {
        setRequestError(error);
        setIsLoading(false);
      });
  };

  return { isLoading, requestError, projectInfo, updateProjectInfo };
};

export default useUpdateProjectInfo;
