import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  TextField,
  Button,
  Divider,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from '../App/styles';
import useGetUnits from '../../hooks/useGetUnits';
import NumberFormat from 'react-number-format';
import Autocomplete from '@material-ui/lab/Autocomplete';

const Unit = ({ control, register, errors, id, removeUnit, data }) => {
  const classes = useStyles();

  const idName = `id${id}`;
  const numberName = `count_of_units${id}`;
  const powerName = `power_unit${id}`;
  const snName = `s_n${id}`;
  const pnName = `p_n${id}`;
  const unName = `u_n${id}`;

  const { error, loading, units } = useGetUnits();

  const filtered_units = units.filter(unit => unit.id === data[powerName] || !unit.is_archived);

  if (!filtered_units.length) {
      return null;
  }

  return (
    <Box className={classes.unit} >
      <TextField
        name={`indexes[${id}]`}
        value={id}
        inputRef={register()}
        type="hidden"
      />
      <TextField
        name={idName}
        defaultValue={data[idName]}
        inputRef={register()}
        type="hidden"
      />
      <NumberFormat
        customInput={TextField}
        id={numberName}
        name={numberName}
        label="Number of units"
        margin="normal"
        error={Boolean(errors[numberName])}
        helperText={errors[numberName] && errors[numberName].message}
        inputRef={register({
          required: "required",
        })}
        defaultValue={data[numberName]}
      />
        <Controller
            as={
                <Autocomplete
                    autoSelect={true}
                    autoComplete={true}
                    options={filtered_units}
                    getOptionLabel={id => {
                        let unit;
                        if (id instanceof Object) {
                            unit = id;
                        } else {
                            unit = filtered_units.find(unit => unit.id === id);
                            if (!unit) {
                                return '';
                            }
                        }
                        return `Name: ${unit.eze_manufacturer.name}, Certificate:${unit.eze_manufacturer.certificate}, Comment: ${unit.eze_manufacturer.comment} ${unit.eze_manufacturer.directive ? "(" + unit.eze_manufacturer.directive + ")": ""} `;
                    }}
                    renderOption={(unit) => {
                        return <span>
                            Name: <strong>{unit.eze_manufacturer.name},</strong>&nbsp;
                            Certificate: <strong>{unit.eze_manufacturer.certificate},</strong>&nbsp;
                            Comment: <strong>{unit.eze_manufacturer.comment}</strong>&nbsp;
                            {unit.eze_manufacturer.directive ? "(" + unit.eze_manufacturer.directive + ")": ""}
                        </span>;
                    }}
                    getOptionSelected={(unit, value) => unit.id === value}
                    renderInput={params => <TextField{...params} id={powerName} label="Power unit" required/>}
                />
            }
            onChange={([, unit]) => unit?.id}
            name={powerName}
            control={control}
            defaultValue={data[powerName]}
        />
      <NumberFormat
        customInput={TextField}
        id={snName}
        name={snName}
        label="Sn, MVA"
        margin="normal"
        error={Boolean(errors[snName])}
        helperText={errors[snName] && errors[snName].message}
        inputRef={register({
          required: "required",
        })}
        defaultValue={data[snName]}
        decimalSeparator=","
      />
      <NumberFormat
        customInput={TextField}
        id={pnName}
        name={pnName}
        label="Pn, MW"
        margin="normal"
        error={Boolean(errors[pnName])}
        helperText={errors[pnName] && errors[pnName].message}
        inputRef={register({
          required: "required",
        })}
        defaultValue={data[pnName]}
        decimalSeparator=","
      />
      <NumberFormat
        customInput={TextField}
        id={unName}
        name={unName}
        label="Un, V"
        margin="normal"
        error={Boolean(errors[unName])}
        helperText={errors[unName] && errors[unName].message}
        inputRef={register({
          required: "required",
        })}
        defaultValue={data[unName]}
        decimalSeparator=","
      />
      <Box className={classes.unitActions} >
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={removeUnit(id)}
        >
          Delete
        </Button>
      </Box>
      <Divider />
    </Box>
  );
};

export default Unit;
