import { AUTH_TOKEN, CURRENT_USER } from './constants';

const getToken = () => window.localStorage.getItem(AUTH_TOKEN);

const isAuthorized = () => Boolean(getToken());

const setToken = (token) => window.localStorage.setItem(AUTH_TOKEN, token);

const setCurrentUser = (user) => window.localStorage.setItem(CURRENT_USER, JSON.stringify(user));

const removeCurrentUser = () => window.localStorage.removeItem(CURRENT_USER);

const getCurrentUser = () => JSON.parse(window.localStorage.getItem(CURRENT_USER));

const isSuperuser = () => getCurrentUser().is_superuser;

const removeToken = () => window.localStorage.removeItem(AUTH_TOKEN);

const fetch = (url, method = 'GET', data = null, options = {}, auth = true) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const commonOptions = {
    mode: 'cors',
    headers: Object.assign(
      headers,
      auth && { 'Authorization': `Bearer ${getToken()}` }
    ),
    method,
    ...options,
  };

  return window.fetch(
    url,
    Object.assign(
      commonOptions,
      data && { body: JSON.stringify(data) },
    ),
  );
};

export {
  fetch,
  setToken,
  removeToken,
  isAuthorized,
  setCurrentUser,
  removeCurrentUser,
  isSuperuser,
  getToken
};
