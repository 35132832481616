import React, {useState} from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import FunctionsIcon from '@material-ui/icons/Functions';
import Unit from './unit';
import useUnits from '../../hooks/useUnits';
import useStyles from '../App/styles';
import Calculations from './calculations';
import GeneralCalculations from './generalCalculations';
import HarmonicViolation from './harmonicViolation';
import GeneralCharts from './generalCharts';
import useCalculations from '../../hooks/useCalculations';
import { unitsToArray } from '../../helpers/units';
import Container from "@material-ui/core/Container";

const Units = ({ register, errors, control, formValues }) => {
  const classes = useStyles();
  const { units, addUnit, removeUnit } = useUnits();
  const { calcResults, error, calculate } = useCalculations();

  const getCalculations = () => {
    calculate(unitsToArray(formValues()));
  }

  return (
    <Card className={classes.card} >
      <CardContent className={classes.cardContent} maxWidth='sm'>
        {units.map((unit) => (
          <Unit
            key={unit.id}
            register={register}
            errors={errors}
            id={unit.id}
            control={control}
            removeUnit={removeUnit}
            data={unit}
          />))}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addUnit}
        >
          Add more
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FunctionsIcon />}
          onClick={getCalculations}
        >
          Calculate
        </Button>
      </CardActions>
      {error && <Alert variant="outlined" severity="error">{error}</Alert>}
      {calcResults && !error  && <GeneralCalculations data={{'grid_operator': calcResults['grid_operator'], 'general_results': calcResults['general_results'], 'power_unit_results': calcResults['power_unit_results']}} />}
      {calcResults && !error  && <Calculations data={{'grid_operator': calcResults['grid_operator'], 'power_unit_results': calcResults['power_unit_results']}} />}
      {calcResults && !error  && <Container key={'xlsx_marks'}><a download='export-marks.xlsx' href={`data:attachment/xlsx;base64,${calcResults['general_results']['xlsx_marks']}`}> Export Marks XLSX </a></Container>}
      {calcResults && !error  && <Container key={'xlsx_all_harmonics'}><a href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${calcResults['general_results']['xlsx_all_harmonics']}`} download="Harmonics.xlsx">Harmonics.xlsx</a></Container>}
      {calcResults && !error  && <GeneralCharts data={calcResults['general_results']} />}
      {calcResults && !error  && <Container key={'xlsx_harmonic_violations'}><a download='Untersuchung-der-Oberschwingungsüberschreitung.xlsx' href={`data:attachment/xlsx;base64,${calcResults['general_results']['xlsx_harmonic_violations']}`}> Export Untersuchung der Oberschwingungsüberschreitung XLSX </a></Container>}
      {calcResults && !error  && <HarmonicViolation data={calcResults['general_results']['harmonic_violations']} />}
    </Card>
  );
};

export default Units;
