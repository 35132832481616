import {Container, TextField, Box} from '@material-ui/core';
import useAbout from "../../hooks/useAbout";
import React from 'react';


const About = () => {
    const {error, loading, aboutInfo} = useAbout({});

    return (
        <Container>
            <Box sx={{maxWidth: '100%'}}
                 noValidate
                 autoComplete="off"
            >

                <TextField id="api-version-box" label="Api version" fullWidth margin="dense"
                           value={aboutInfo.api_version} defaultValue=""
                           InputProps={{
                               readOnly: true,
                           }}/>
                <TextField id="build-date-box" label="Build date" fullWidth margin="dense"
                           value={aboutInfo.api_build_date} defaultValue=""
                           InputProps={{
                               readOnly: true,
                           }}/>
            </Box>
        </Container>
    );
}

export default About;
