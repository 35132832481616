import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  calcButton: {

  },
  root: {
    width: '100%',
    // maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    // maxHeight: 1000,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  resultItem: {
    fontWeight: 'bold',
  },
  downloadButton: {
    padding: "2px 10px 2px 0"
  }
}));

export default useStyles;
