import { useState, useEffect } from 'react';
import { fetch } from '../helpers/api';
import {DATEBANK, UPDATE_POWER_UNIT} from '../helpers/urls';

const useDatebank = () => {
  const [datebank, setDatebank] = useState([]);
  const [loading, setLoading] = useState(true);
  const [harmonicColumns, setHarmonicColumns] = useState([]);
  const [error, setError] = useState(null);
  const [disableArchiving, setDisableArchiving] = useState(false);

  const updatePowerUnit = (power_unit_id, data) => {
    setDisableArchiving(true);
    fetch(UPDATE_POWER_UNIT(power_unit_id), 'PATCH', data)
      .then((res) => {
        let items = [...datebank];
        let affected_item_index = Object.keys(items).find(index => items[index].id === power_unit_id);
        let affected_item = {...items[affected_item_index]};
        affected_item.is_archived = data.is_archived;
        items[affected_item_index] = affected_item
        setDatebank([...items]);
        setDisableArchiving(false)
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(
    () => {
      fetch(DATEBANK)
        .then((res) => res.json())
        .then((res) => {
          setDatebank(res.data);
          setHarmonicColumns(res.harmonic_columns)
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
       });
    },
    [],
  );

  return { datebank, loading, updatePowerUnit, disableArchiving, harmonicColumns, error };
};

export default useDatebank;
