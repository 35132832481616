import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,

} from '@material-ui/core';
import useStyles from './styles';
import {STANDARD_VED_AR_N_4110} from "../../helpers/constants";

const Calculations = ({ data }) => {
  const classes = useStyles();
  const power_unit_results = data['power_unit_results'];
  const standard = data['grid_operator']['standard'];

  return (
      <List className={classes.root} subheader={<li />}>
        {power_unit_results.map((item) => (
          <li key={`section-${item['power_unit']}`} className={classes.listSection}>
            <ul className={classes.ul}>
              <ListSubheader>{item['power_unit']}</ListSubheader>
                {standard === STANDARD_VED_AR_N_4110 && (
                  <ListItem key={'item-interpoolkf'}>
                    <ListItemText primary={`Interpol. kf: ${item.results.interpol_kf.toLocaleString('de')}`} />
                  </ListItem>
                )}
                <ListItem key={'item-item-interpoolku'}>
                  <ListItemText primary={`Interpol. ku: ${item.results.interpol_ku.toLocaleString('de')}`} />
                </ListItem>
                <ListItem key={'item-item-interpoolc'}>
                  <ListItemText primary={`Interpol. c: ${item.results.interpol_c.toLocaleString('de')}`} />
                </ListItem>
            </ul>
          </li>
        ))}
      </List>
  );
};

export default Calculations;
