import { getToken } from '../helpers/api';
import { IMPORT_POWER_UNITS } from '../helpers/urls';
import { useState} from "react";

const useImportPowerUnit = () => {
    const [file, setFile] = useState({
        file: null,
        filename: 'no file selected'
    });
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('todo');

    const importPowerUnit = () => {
        setStatus('pending');

        const body = new FormData();
        body.append('file', file.file);

        const options = {
          mode: 'cors',
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          method: 'POST',
          body: body,
      };

      window.fetch(IMPORT_POWER_UNITS, options)
          .then((res) => {
              if (res.status === 200) {
                  setStatus('success');
                  setError('');
              } else {
                  return res.json().then((data) => {
                      setStatus('failed');
                      setError(data.message);
                  });
              }
          })
          .catch(() => {
              setStatus('failed');
              setError('Unknown error');
          });
    }

    const handleFileSelect = (e) => {
        if (e.target.files.length) {
            setFile({
                file: e.target.files[0],
                filename: e.target.files[0].name
            })
        }
    }

  return { importPowerUnit, handleFileSelect, file: file, error: error, status: status};
};

export default useImportPowerUnit;
