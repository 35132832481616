import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,

} from '@material-ui/core';
import useStyles from './styles';
import {STANDARD_VED_AR_N_4110, STANDARD_VED_AR_N_4120} from "../../helpers/constants";

const GeneralCalculations = ({ data }) => {
  const classes = useStyles();
  const general_results = data['general_results'];
  const power_unit_results = data['power_unit_results'];
  const standard = data['grid_operator']['standard'];

  return (
      <List className={classes.root} subheader={<li />}>
          <li key='general_calculations' className={classes.listSection}>
            <ul className={classes.ul}>
              <ListSubheader>General Calculations</ListSubheader>

                {/*item-item-delta_uers*/}
                {standard === STANDARD_VED_AR_N_4110 && power_unit_results.map((item, key) => (
                    <ListItem key={'item-item-delta_uers'}>
                        <ListItemText primary={`schnelle Spannungsänderung EZE. Δuers (B.15): ${item.results.delta_uers.toLocaleString('de')} (EZE${key+1} ${item['power_unit']})`} />
                    </ListItem>
                ))}

                {standard === STANDARD_VED_AR_N_4120 && power_unit_results.map((item, key) => (
                    <ListItem key={'item-item-delta_uers'}>
                        <ListItemText primary={`Spannungsänderung Δuers (EZE) (B.12): ${item.results.delta_uers.toLocaleString('de')} (EZE${key+1} ${item['power_unit']})`} />
                    </ListItem>
                ))}

                {/*general_delta_eurs*/}
                {standard === STANDARD_VED_AR_N_4110 && (
                    <ListItem key={'general_delta_eurs'}>
                        <ListItemText primary={`schnelle Spannungsänderung EZA. Δuers (3.6.1.2.1): ${general_results.general_delta_eurs.toLocaleString('de')}`} />
                    </ListItem>
                )}

                {standard === STANDARD_VED_AR_N_4120 && (
                    <ListItem key={'general_delta_eurs'}>
                        <ListItemText primary={`Spannungsänderung Δuers (EZA) (3.6.1.3): ${general_results.general_delta_eurs.toLocaleString('de')}`} />
                    </ListItem>
                )}

                {/*general_p_lti_eze_${key+1}*/}
                {standard === STANDARD_VED_AR_N_4110 && general_results.general_p_lt.map((val, key) =>
                    <ListItem key={`general_p_lti_eze_${key+1}`}>
                        <ListItemText primary={`Plt_dauer (EZE${key+1}) (B.16): ${val.toLocaleString('de', {minimumFractionDigits: 5})}`} />
                    </ListItem>
                )}

                {/*general_calc_p_lt_ges*/}
                {standard === STANDARD_VED_AR_N_4110 && (
                    <ListItem key={'general_calc_p_lt_ges'}>
                        <ListItemText primary={`Plt ges (B.17): ${general_results.general_calc_p_lt_ges.toLocaleString('de', {minimumFractionDigits: 5})}`} />
                    </ListItem>
                )}

                {/*item-item-plt*/}
                {standard === STANDARD_VED_AR_N_4110 && power_unit_results.map((item, key) => (
                <div>
                    <ListItem key={'item-item-plt'}>
                        <ListItemText primary={`Langzeitflicker im Dauerbetrieb EZE. Plt (B.18): ${item.results.plt.toLocaleString('de', {minimumFractionDigits: 5})} (EZE${key+1} ${item['power_unit']})`} />
                    </ListItem>
                    <ListItem key={'item-item-plt'}>
                        <ListItemText primary={`Alle EZE: ${item.results.alle_eze.toLocaleString('de')} (EZE${key+1} ${item['power_unit']})`} />
                    </ListItem>
                </div>
                ))}

                {standard === STANDARD_VED_AR_N_4120 && power_unit_results.map((item, key) => (
                    <div>
                        <ListItem key={'item-item-plt'}>
                            <ListItemText primary={`Langzeitflicker Plt_dauer (EZE) (B.13): ${item.results.plt.toLocaleString('de', {minimumFractionDigits: 5})} (EZE${key+1} ${item['power_unit']})`} />
                        </ListItem>
                    </div>
                ))}

                {/*general_plt_res*/}
                {standard === STANDARD_VED_AR_N_4110 && (
                    <ListItem key={'general_plt_res'}>
                        <ListItemText primary={`Langzeitflicker im Dauerbetrieb. Plt_res (B. 19): ${general_results.general_plt_res.toLocaleString('de')}`} />
                    </ListItem>
                )}

                {standard === STANDARD_VED_AR_N_4120 && (
                    <ListItem key={'general_plt_res'}>
                        <ListItemText primary={`Langzeitflicker Plt_dauer (EZA) (B.14): ${general_results.general_plt_res.toLocaleString('de')}`} />
                    </ListItem>
                )}

                {/*7*/}
                <ListItem key={'r_lastf'}>
                    <ListItemText primary={`R lastf: ${general_results.r_lastf.toLocaleString('de')}`} />
                </ListItem>
                <ListItem key={'x_lastf'}>
                    <ListItemText primary={`X lastf: ${general_results.x_lastf.toLocaleString('de')}`} />
                </ListItem>
                {standard === STANDARD_VED_AR_N_4110 && (
                <div>
                    <ListItem key={'r_tgleich'}>
                        <ListItemText primary={`R t,gleich: ${general_results.r_tgleich.toLocaleString('de')}`} />
                    </ListItem>
                    <ListItem key={'x_tgleich'}>
                        <ListItemText primary={`X t,gleich: ${general_results.x_tgleich.toLocaleString('de')}`} />
                    </ListItem>
                </div>
                )}
                <ListItem key={'r_1gleich'}>
                    <ListItemText primary={`R 1,gleich: ${general_results.r_1gleich.toLocaleString('de')}`}/>
                </ListItem>
                <ListItem key={'x_1gleich'}>
                    <ListItemText primary={`X 1,gleich: ${general_results.x_1gleich.toLocaleString('de')}`}/>
                </ListItem>
                <ListItem key={'r_1prev'}>
                    <ListItemText primary={`R 1,vorgel: ${general_results.r_1prev.toLocaleString('de')}`}/>
                </ListItem>
                <ListItem key={'x_1prev'}>
                    <ListItemText primary={`X 1,vorgel: ${general_results.x_1prev.toLocaleString('de')}`}/>
                </ListItem>
                <ListItem key={'r_3prev'}>
                    <ListItemText primary={`R 3,vorgel: ${general_results.r_3prev.toLocaleString('de')}`}/>
                </ListItem>
                <ListItem key={'x_3prev'}>
                    <ListItemText primary={`X 3,vorgel: ${general_results.x_3prev.toLocaleString('de')}`} />
                </ListItem>

            </ul>
          </li>
      </List>
  );
};

export default GeneralCalculations;
