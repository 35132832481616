import React, { Fragment } from 'react';
import {
  Link,
} from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Button } from '@material-ui/core';

import useStyles from './styles';
import { removeToken, removeCurrentUser } from '../../helpers/api';

import logo from './logo.png';


function CustomAppBar({ showLinks }) {
  const classes = useStyles();

  const logout = () => {
    removeToken();
    removeCurrentUser();
  }

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <img src={logo} alt="logo" className={classes.logo} />
        { showLinks &&
          <Fragment>
            <Link
              to='/projects'
              variant="body2"
            >
              Projects
            </Link>
            <Link
              to='/datebank'
              variant="body2"
            >
              Datebank
            </Link>
            <Link
              to='/about'
              variant="body2"
            >
              About
            </Link>
            <Button
              color="inherit"
              onClick={logout}
              component={Link}
              to="/login"
            >
              Logout
            </Button>
          </Fragment>
        }
      </Toolbar>
    </AppBar>
  );
}

export default CustomAppBar;