import { useCallback } from "react";
import { useHistory } from "react-router-dom";

const useProjectsActions = () => {
  const history = useHistory();
  const goToCreate = useCallback(
    () => history.push("create-project"),
    [history]
  );

  return [
    {
      icon: "create-project",
      isFreeAction: true,
      onClick: goToCreate,
    },
    {
      icon: "manage-power-units",
      onClick: (id) => () => {
        history.push(`/project/${id}/add-power-units`);
      },
    },
    {
      icon: "project-detail",
      onClick: (id) => () => {
        history.push(`/project/${id}/info`);
      },
    },
  ];
};

export default useProjectsActions;
