import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetch } from '../helpers/api';
import { PROJECT_POWER_UNITS } from '../helpers/urls';

const useUnitProjectRelation = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  
  const createRelation = (projectId, data) => {
    fetch(PROJECT_POWER_UNITS(projectId), 'POST', data)
      .then((res) => {
        history.push('/projects');
      })
      .catch((error) => {
        setError(error);
      });
  };

  return { error, createRelation };
};

export default useUnitProjectRelation;

