import React from 'react';
import {
  Container,

} from '@material-ui/core';
import useStyles from './styles';

const GeneralCharts = ({ data }) => {
  const classes = useStyles();

  return (
    <Container>
      <img width="50%" src={`data:image/png;base64, ${data.chart_odd_harmonic}`}/>
      <img width="50%" src={`data:image/png;base64, ${data.chart_even_harmonic}`}/>
      <img width="50%" src={`data:image/png;base64, ${data.chart_high_frequencies_harmonic}`}/>
      <img width="50%" src={`data:image/png;base64, ${data.chart_interharmonics_harmonic}`}/>

      <a className={classes.downloadButton} href={`data:image/png;base64, ${data.chart_odd_harmonic}`} download="Diagram_1.png">Diagram 1</a>
      <a className={classes.downloadButton} href={`data:image/png;base64, ${data.chart_even_harmonic}`} download="Diagram_2.png">Diagram 2</a>
      <a className={classes.downloadButton} href={`data:image/png;base64, ${data.chart_high_frequencies_harmonic}`} download="Diagram_3.png">Diagram 3</a>
      <a className={classes.downloadButton} href={`data:image/png;base64, ${data.chart_interharmonics_harmonic}`} download="Diagram_4.png">Diagram 4</a>
    </Container>
  );
};

export default GeneralCharts;
