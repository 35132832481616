import { API_ROOT } from './constants';

export const LOGIN = `${API_ROOT}/users/login`;
export const CURRENT_USER = `${API_ROOT}/users/me`;
export const PROJECTS = `${API_ROOT}/projects`;
export const POWER_UNITS = `${API_ROOT}/power_units`;
export const DATEBANK = `${API_ROOT}/datebank`;
export const IMPORT_POWER_UNITS = `${API_ROOT}/power_units/import`
export const UPDATE_POWER_UNIT = (power_unit_id) => `${API_ROOT}/power_units/${power_unit_id}`
export const PROJECT_VIEW = (project_id) => `${API_ROOT}/projects/${project_id}`;
export const CALCULATIONS_VIEW = (project_id) => `${API_ROOT}/projects/${project_id}/calculate`;
export const PROJECT_POWER_UNITS = (project_id) => `${API_ROOT}/projects/${project_id}/power_units`;
export const GRID_OPERATION_CREATE = (project_id) => `${API_ROOT}/projects/${project_id}/grid_operation`;
export const GRID_OPERATION_EDIT = (project_id, grid_operation_id) => `${API_ROOT}/projects/${project_id}/grid_operation/${grid_operation_id}/`;
export const ABOUT_INFO = `${API_ROOT}/about`
