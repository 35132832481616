import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listButton: {
    height: '40px',
    width: '100px',
    marginRight: '10px',
    "& span": {
      lineHeight: '17px',
    }
  },
});

export default useStyles;
