import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetch } from '../helpers/api';
import { CALCULATIONS_VIEW } from '../helpers/urls';

const useCalculations = () => {
  const { projectId } = useParams();
  const [calcResults, setCalculations] = useState(null);
  const [error, setError] = useState(null);

  const calculate = (data) => {
    setError(null);
    fetch(CALCULATIONS_VIEW(projectId), 'POST', data)
      .then((res) => {
        if (res.status === 400) {
          return res.json()
            .then((err) => { throw err; });
        }
        return res.json();
      })
      .then((res) => {
        setCalculations(res);
      })
      .catch((error) => {
        setError(error[0]);
      });
  };

  return { calcResults, error, calculate };
};

export default useCalculations;
