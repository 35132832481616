import { useState, useEffect } from 'react';
import { fetch } from '../helpers/api';
import { POWER_UNITS } from '../helpers/urls';

const useGetUnits = () => {
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(
    () => {
      fetch(POWER_UNITS)
        .then((res) => res.json())
        .then((res) => {
          setUnits(res);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
       });
    },
    [],
  );

  return { units, loading, error };
};

export default useGetUnits;
