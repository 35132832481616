import React from 'react';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {
    Container,
    FormControl,
    Button
} from '@material-ui/core';
import FormHelperText from "@material-ui/core/FormHelperText";
import {makeStyles} from '@material-ui/core/styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Box from "@material-ui/core/Box";
import useImportPowerUnit from "../../hooks/useImportPowerUnit";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

const ImportPowerUnit = ({handleClose}) => {

    const history = useHistory();
    const {importPowerUnit, handleFileSelect, file, error, status} = useImportPowerUnit();
    const {handleSubmit} = useForm();
    const classes = useStyles();

    useEffect(() => {
        if (status === 'success') {
            console.log(status);
            history.go(0);
        }
    });

    return (
        <Container maxWidth="sm">
            <FormControl
                component="form"
                margin="normal"
                fullWidth
                autoComplete="off"
                onSubmit={handleSubmit(importPowerUnit)}
            >
                <div className={classes.root}>

                    <h3 >{'Import Power Unit'}</h3>
                    <br/>
                    <input
                        name={'file'}
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleFileSelect}
                        disabled={status === 'pending'}
                    />

                    <label htmlFor="contained-button-file">
                        <Button
                            variant="contained"
                            color="default"
                            component="span"
                            startIcon={<InsertDriveFileIcon/>}
                            disabled={status === 'pending'}
                        >
                            Select xlsx file
                        </Button>
                    </label>

                    <FormHelperText>{file.filename}</FormHelperText>

                    <FormHelperText error={true}>{error}&nbsp;</FormHelperText>

                    <Box align="right">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{marginLeft: 5}}
                            disabled={status === 'pending'}
                        >
                            Import
                        </Button>
                    </Box>
                </div>
            </FormControl>
        </Container>
    )
};

export default ImportPowerUnit;
