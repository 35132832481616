import React from 'react';
import {
    Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";


const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
    },
    container: {
        maxHeight: 460,
    },
});

const HarmonicViolation = ({ data }) => {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
          <TableContainer className={classes.container}>
              <Table aria-label="sticky table">
                  <TableHead>
                      <TableRow>
                          <TableCell>Oberschwingung</TableCell>
                          <TableCell align="right">Zulässiger Strom</TableCell>
                          <TableCell align="right">Tatsächlicher Strom</TableCell>
                          <TableCell align="right">Differenz in %</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {data.map((row) => (
                          <TableRow key={row.tick}>
                              <TableCell component="th" scope="row">
                                  {row.tick}
                              </TableCell>
                              <TableCell align="right">{row.allowed.toLocaleString('de')}</TableCell>
                              <TableCell align="right">{row.actual.toLocaleString('de')}</TableCell>
                              <TableCell align="right">{row.difference.toLocaleString('de')}</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
        </Container>
    );
};

export default HarmonicViolation;
