export const unitsToArray = (fields) => {
  const result = [];
  let indexes = [];

  if (fields.indexes !== undefined) {
    indexes = fields.indexes;
  } else {
    Object.keys(fields).forEach((key) => {
      if (key.startsWith('indexes[')) {
        indexes.push(fields[key]);
      }
    })
  }

  for (const index in  indexes) {
    const resultItem = {
      power_unit: Number(fields[`power_unit${index}`]),
      count_of_units: Number(fields[`count_of_units${index}`]),
      s_n: Number(fields[`s_n${index}`].replace(/,/g, '.')),
      p_n: Number(fields[`p_n${index}`].replace(/,/g, '.')),
      u_n: Number(fields[`u_n${index}`].replace(/,/g, '.')),
    };

    if (fields[`id${index}`] !== '') {
      resultItem['id'] = Number(fields[`id${index}`]);
    }

    result.push(resultItem);
  }

  return result;
};
