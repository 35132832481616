import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { convertGridOperationData, generateLabelElement } from "./utils";

import useStyles from "../App/styles";

const standard4120GridOperatorFields = ["u_n", "s_kV", "line_impedance", "s_0"];

const GridOperator = ({ data }) => {
  const classes = useStyles();
  let gridOperationData = [];
  if (data) {
    let counter = null;
    gridOperationData = convertGridOperationData(data)
      .filter((item) => {
        if (data.standard === "VDE-AR-N_4110") {
          if (item[0] === "s_0") return false;
          else return true;
        }
        if (data.standard === "VDE-AR-N_4120") {
          let isValidField = false;

          for (let field of standard4120GridOperatorFields) {
            item[0] === field ? (isValidField = true) : (isValidField = false);
            if (isValidField) return isValidField;
          }
        }
      })
      .map((item, idx) => {
        if (item[0] === "transformers_powers" && !counter) {
          counter = idx - 1;
        }
        return {
          name: generateLabelElement(item[0], idx - counter),
          value: item[1] ? item[1].toLocaleString("de") : 0,
          id: idx + 1,
        };
      });
  }

  for (let field of standard4120GridOperatorFields) {
  }

  return (
    <TableContainer component={Paper} className={classes.gridTable}>
      <Typography
        className={classes.title + " " + classes.titlePadding}
        variant="h5"
      >
        Grid Operator
      </Typography>
      <Table>
        <TableBody>
          {gridOperationData.map((row) => (
            <TableRow key={row.id}>
              <TableCell className={classes.bold}>{row.name}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GridOperator;
