import { useState } from "react";
import { useParams } from "react-router-dom";
import { fetch } from "../helpers/api";
import { GRID_OPERATION_CREATE, GRID_OPERATION_EDIT } from "../helpers/urls";

const removeEmpty = (data) =>
  Object.entries(data).reduce(
    (acc, [key, value]) => ({ ...acc, [`${key}`]: value === "" ? 0 : value }),
    {}
  );

const useGridOperation = () => {
  const { projectId } = useParams();
  const [isSend, setSendStatus] = useState(false);
  const [error, setError] = useState(null);

  const requestGridOperation = (isCreate, data, gridOperationId) => {
    const fetchParams = isCreate
      ? [GRID_OPERATION_CREATE(projectId), "POST", removeEmpty(data)]
      : [
          GRID_OPERATION_EDIT(projectId, gridOperationId),
          "PUT",
          removeEmpty(data),
        ];
    setSendStatus(false);
    fetch(...fetchParams)
      .then((res) => {
        setSendStatus(true);
      })
      .catch((error) => {
        setError(error);
      });
  };

  return { isSend, setSendStatus, error, requestGridOperation };
};

export default useGridOperation;
