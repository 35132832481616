import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Typography,
  FormControl,
  TextField,
  IconButton,
  Button,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ProjectSchema from './validation';
import useStyles from '../App/styles';
import useCreateProject from '../../hooks/useCreateProject';

const CreateProject = () => {
  const classes = useStyles();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({
    validationSchema: ProjectSchema,
  });
  const { error: createError, createProject } = useCreateProject();

  const sendRequest = (fields) => {
    createProject(fields)
  };

  const goBack = useCallback(
    () => history.push('projects'),
    [history],
  );

  return (
    <Container
      maxWidth="sm"
    >
      <Box className={classes.title} >
        <Typography variant="h5">
          Create Project
        </Typography>
        <IconButton
          color="primary"
          aria-label="back"
          onClick={goBack}
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <FormControl
        component="form"
        margin="normal"
        fullWidth
        autoComplete="off"
        onSubmit={handleSubmit(sendRequest)}
      >
        <TextField
          id="name"
          name="name"
          label="Name *"
          margin="normal"
          error={Boolean(errors.name)}
          helperText={errors.name && errors.name.message}
          inputRef={register}
        />
        <TextField
          id="external_number"
          name="external_number"
          label="Ext. number *"
          margin="normal"
          error={Boolean(errors.external_number)}
          helperText={errors.external_number && errors.external_number.message}
          inputRef={register}
        />
        <TextField
          id="manager"
          name="manager"
          label="Manager *"
          margin="normal"
          error={Boolean(errors.manager)}
          helperText={errors.manager && errors.manager.message}
          inputRef={register}
        />
        <Button
          variant="outlined"
          color="primary"
          type="submit"
        >
          Create
        </Button>
      </FormControl>
    </Container>
  )
};

export default CreateProject;
