import { Button, Container, FormControl, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import NumberFormat from "react-number-format";

const GridOperatorForm = ({ sendRequest, data, standard }) => {
  const { register, errors, handleSubmit } = useForm();
  const randId = () => Math.random().toString(36).substr(2, 9);

  const calculationOptions = [
    { label: "VDE-AR-N 4110", value: "VDE-AR-N_4110" },
    { label: "VDE-AR-N 4120", value: "VDE-AR-N_4120" },
  ];

  const [calcOption, setCalcOption] = useState(calculationOptions[0]);

  useEffect(() => {
    const standardValue = calculationOptions.find(
      (item) => item.value === standard
    );
    setCalcOption(standardValue ? standardValue : calculationOptions[0]);
  }, []);

  const transformersPowersWithKeys = [];
  data.transformers_powers &&
    data.transformers_powers.forEach((item) => {
      transformersPowersWithKeys.push({
        id: randId(),
        val: item,
      });
    });

  const [transformersPowers, setTransformersPowers] = useState(
    transformersPowersWithKeys
  );

  const addTransformerPower = () =>
    setTransformersPowers((transformersPowers) => {
      const res = [...transformersPowers];
      res.push({ id: randId(), val: 0 });
      return res;
    });

  const removeTransformerPower = (id) => () =>
    setTransformersPowers((transformersPowers) =>
      transformersPowers.filter((item) => item.id !== id)
    );

  const handleOnChange = (optionValue) => {
    setCalcOption(optionValue);
  };

  const sendRequestOnSubmit = (fields) => {
    const transformed = {};
    for (const [key, value] of Object.entries(fields)) {
      transformed[key] = Number(value.replace(/,/g, "."));
    }
    transformed.transformers_powers = [...transformersPowers].map((item) =>
      Number(String(item.val).replace(/,/g, "."))
    );
    sendRequest({ ...transformed, standard: calcOption.value }, data.id);
  };

  return (
    <Container maxWidth="sm">
      <Autocomplete
        id="combo-box-demo"
        options={calculationOptions}
        getOptionLabel={(option) => option.label}
        onChange={(e, newValue) => handleOnChange(newValue)}
        style={{ width: 300 }}
        value={calcOption}
        defaultValue={calcOption}
        disableClearable={true}
        renderInput={(params) => (
          <TextField {...params} size="small" variant="outlined" />
        )}
      />
      <FormControl
        component="form"
        margin="normal"
        fullWidth
        autoComplete="off"
        onSubmit={handleSubmit(sendRequestOnSubmit)}
      >
        <NumberFormat
          customInput={TextField}
          id="u_n"
          name="u_n"
          label="u_n"
          margin="normal"
          helperText={errors.name && errors.name.message}
          inputRef={register()}
          defaultValue={data["u_n"]}
          decimalSeparator=","
        />
        {calcOption.label === "VDE-AR-N 4110" && (
          <NumberFormat
            customInput={TextField}
            id="u_c"
            name="u_c"
            label="U_c in kV"
            margin="normal"
            helperText={errors.name && errors.name.message}
            inputRef={register()}
            defaultValue={data["u_c"]}
            decimalSeparator=","
          />
        )}
        <NumberFormat
          customInput={TextField}
          id="s_kV"
          name="s_kV"
          label="S_kv in MVA"
          margin="normal"
          helperText={errors.name && errors.name.message}
          inputRef={register()}
          defaultValue={data["s_kV"]}
          decimalSeparator=","
        />
        <NumberFormat
          customInput={TextField}
          id="line_impedance"
          name="line_impedance"
          label="Netzimpedanzwinkel"
          margin="normal"
          error={Boolean(errors.manager)}
          helperText={errors.manager && errors.manager.message}
          inputRef={register()}
          defaultValue={data["line_impedance"]}
          decimalSeparator=","
        />
        {calcOption.label === "VDE-AR-N 4110" && (
          <NumberFormat
            customInput={TextField}
            id="total"
            name="total"
            label="SGesamt in MVA"
            margin="normal"
            error={Boolean(errors.manager)}
            helperText={errors.manager && errors.manager.message}
            inputRef={register()}
            defaultValue={data["total"]}
            decimalSeparator=","
          />
        )}
        {calcOption.label === "VDE-AR-N 4120" ? (
          <NumberFormat
            customInput={TextField}
            id="s_0"
            name="s_0"
            label="S0 in MVA"
            margin="normal"
            error={Boolean(errors.manager)}
            helperText={errors.manager && errors.manager.message}
            inputRef={register()}
            defaultValue={data["s_0"]}
            decimalSeparator=","
          />
        ) : (
          <NumberFormat
            customInput={TextField}
            id="network"
            name="network"
            label="SNetz in MVA"
            margin="normal"
            error={Boolean(errors.manager)}
            helperText={errors.manager && errors.manager.message}
            inputRef={register()}
            defaultValue={data["network"]}
            decimalSeparator=","
          />
        )}
        {calcOption.label === "VDE-AR-N 4110" && (
          <>
            <NumberFormat
              customInput={TextField}
              id="ripple_control_frequency"
              name="ripple_control_frequency"
              label="Rundsteuerfrequenz"
              margin="normal"
              error={Boolean(errors.manager)}
              helperText={errors.manager && errors.manager.message}
              inputRef={register()}
              defaultValue={data["ripple_control_frequency"]}
              decimalSeparator=","
            />
            <NumberFormat
              customInput={TextField}
              id="k_B"
              name="k_B"
              label="kB (0)"
              margin="normal"
              error={Boolean(errors.manager)}
              helperText={errors.manager && errors.manager.message}
              inputRef={register()}
              defaultValue={data["k_B"]}
              decimalSeparator=","
            />
            <NumberFormat
              customInput={TextField}
              id="k_E"
              name="k_E"
              label="kE (1.35)"
              margin="normal"
              error={Boolean(errors.manager)}
              helperText={errors.manager && errors.manager.message}
              inputRef={register()}
              defaultValue={data["k_E"]}
              decimalSeparator=","
            />
            <NumberFormat
              customInput={TextField}
              id="k_S"
              name="k_S"
              label="kS (0)"
              margin="normal"
              error={Boolean(errors.manager)}
              helperText={errors.manager && errors.manager.message}
              inputRef={register()}
              defaultValue={data["k_S"]}
              decimalSeparator=","
            />
            <NumberFormat
              customInput={TextField}
              id="g"
              name="g"
              label="g (1 für Wind; 0,9 für PV)"
              margin="normal"
              error={Boolean(errors.manager)}
              helperText={errors.manager && errors.manager.message}
              inputRef={register()}
              defaultValue={data["g"]}
              decimalSeparator=","
            />
            {transformersPowers.map((item, k) => (
              <div key={item.id}>
                <NumberFormat
                  customInput={TextField}
                  label={`Trafoleistung ${k + 1} (MVA)`}
                  margin="normal"
                  error={Boolean(errors.manager)}
                  helperText={errors.manager && errors.manager.message}
                  defaultValue={item.val}
                  onChange={(event) => {
                    transformersPowers[k].val = event.target.value;
                  }}
                  decimalSeparator=","
                />
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={removeTransformerPower(item.id)}
                ></Button>
              </div>
            ))}
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={addTransformerPower}
            >
              Hinzufügen trafoleistung
            </Button>
            <NumberFormat
              customInput={TextField}
              id="resonance_factor_ku"
              name="resonance_factor_ku"
              label="Resonanzfaktoren ku (1)"
              margin="normal"
              error={Boolean(errors.manager)}
              helperText={errors.manager && errors.manager.message}
              inputRef={register()}
              defaultValue={data["resonance_factor_ku"]}
              decimalSeparator=","
            />
            <NumberFormat
              customInput={TextField}
              id="resonance_factor_kb"
              name="resonance_factor_kb"
              label="Resonanzfaktoren kb (0,95)"
              margin="normal"
              error={Boolean(errors.manager)}
              helperText={errors.manager && errors.manager.message}
              inputRef={register()}
              defaultValue={data["resonance_factor_kb"]}
              decimalSeparator=","
            />
            <NumberFormat
              customInput={TextField}
              id="resonance_factor_kv"
              name="resonance_factor_kv"
              label="Resonanzfaktoren kv (1,5)"
              margin="normal"
              error={Boolean(errors.manager)}
              helperText={errors.manager && errors.manager.message}
              inputRef={register()}
              defaultValue={data["resonance_factor_kv"]}
              decimalSeparator=","
            />
            <NumberFormat
              customInput={TextField}
              id="rt"
              name="rt"
              label="R t [Ω]"
              margin="normal"
              error={Boolean(errors.manager)}
              helperText={errors.manager && errors.manager.message}
              inputRef={register()}
              defaultValue={data["rt"]}
              decimalSeparator=","
            />
            <NumberFormat
              customInput={TextField}
              id="xt"
              name="xt"
              label="X t [Ω]"
              margin="normal"
              error={Boolean(errors.manager)}
              helperText={errors.manager && errors.manager.message}
              inputRef={register()}
              defaultValue={data["xt"]}
              decimalSeparator=","
            />
          </>
        )}
        <Button variant="outlined" color="primary" type="submit">
          Save
        </Button>
      </FormControl>
    </Container>
  );
};

export default GridOperatorForm;
